import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Container,
  EditMode,
  PwdModal,
  SelectAvatarModal,
  ViewMode,
} from "../components";
import { useNfcStore } from "src/stores";

export const Home = (props: any) => {
  const nfcId = props?.match?.params?.nfc || null;

  const { getNfcData } = useNfcStore();

  const [nfc, setNfc] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSelectAvatarModalOpen, setIsSelectAvatarModalOpen] = useState(false);
  const [updateAvatar, setUpdateAvatar] = useState("Avatar_Sample.png");

  const getNfcDataCb = useCallback(() => {
    getNfcData(nfcId).then((result) => {
      if (!result) return;
      setNfc(result);
      setUpdateAvatar(result?.avatar || "Avatar_Sample.png");
      if (!result.has_updated) setEditMode(true);
    });
  }, [getNfcData, nfcId]);

  useEffect(() => {
    if (!nfcId) return;
    getNfcDataCb();
  }, [nfcId, getNfcDataCb]);

  const isEditMode = useMemo(() => {
    if (!nfc || editMode) return true;
    return false;
  }, [nfc, editMode]);

  return (
    <Container>
      {/* Background Elements */}
      <div className="z-[10]">
        <img
          src="/assets/Background_1.png"
          alt="bg-ele-1"
          className="w-[55%] absolute top-[-40px] left-[-40px]"
        />
        <img
          src="/assets/Background_2.png"
          alt="bg-ele-2"
          className="w-[80%] absolute top-[-40px] left-[50%] ml-[-45%]"
        />
        <img
          src="/assets/Background_3.png"
          alt="bg-ele-3"
          className="w-[50%] absolute bottom-[-20px] right-[-20px]"
        />
      </div>

      {/* Edit Mode */}
      {isEditMode && (
        <EditMode
          nfc={nfc}
          nfcId={nfcId}
          setEditMode={setEditMode}
          getNfcDataCb={getNfcDataCb}
          setIsSelectAvatarModalOpen={setIsSelectAvatarModalOpen}
          updateAvatar={updateAvatar}
        />
      )}

      {/* NFC Card */}
      {!isEditMode && (
        <ViewMode
          nfc={nfc}
          setEditMode={setEditMode}
          setIsModalOpen={setIsModalOpen}
        />
      )}

      {/* Modal */}
      <PwdModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setEditMode={setEditMode}
        nfc={nfc}
      />

      <SelectAvatarModal
        isModalOpen={isSelectAvatarModalOpen}
        setIsModalOpen={setIsSelectAvatarModalOpen}
        nfc={nfc}
        setUpdateAvatar={setUpdateAvatar}
      />
    </Container>
  );
};
