import React, { useEffect } from "react";
import { useLocation } from "react-router";

export const Container = (props: any) => {
  const { children } = props;
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location.pathname]);

  return (
    <div
      className={`w-screen min-h-screen bg-cover overflow-hidden`}
      style={{
        backgroundImage: 'url("/assets/Background.png")',
      }}
    >
      <div className="max-w-[500px] p-[20px] relative mx-auto min-h-screen">
        {children}
      </div>
    </div>
  );
};
