import { action } from "mobx";
import axios from "axios";
import * as Configs from "../config";
import { isResultSuccess } from "./api";

export class NfcStore {
  @action
  async getNfcData(id: string) {
    const url = `${Configs.API_URL}/nfc/${id}`;
    try {
      const result: any = await axios.get(url);
      if (!isResultSuccess(result)) return null;
      return result["data"]["data"];
    } catch (e) {
      return null;
    }
  }

  @action
  async updateNfcData(id: string, data: any) {
    const url = `${Configs.API_URL}/nfc/${id}`;
    try {
      const result: any = await axios.put(url, data);
      if (!isResultSuccess(result)) return false;
      return result["data"]["success"];
    } catch (e) {
      return false;
    }
  }
}

export const STORE_NFC = "nfcStore";
