import { Modal } from "antd";
import React from "react";

const AVATAR_LIST_NUM = 20;
const AVATAR_LIST = new Array(AVATAR_LIST_NUM).fill(null);

export const SelectAvatarModal = (props: any) => {
  const { isModalOpen, setIsModalOpen, setUpdateAvatar } = props;

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      title={null}
      visible={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      <div className="my-[30px] flex flex-col justify-center align-center text-left relative">
        {/* Title */}
        <div className="absolute w-full top-[-80px] left-[0] right-[0]">
          <div
            className="bg-cover block h-[60px] w-[230px] mx-auto cursor-pointer"
            style={{
              backgroundImage: 'url("/assets/Popup_Title Box.png")',
            }}
          >
            <p className="leading-[60px] w-[230px] text-[#fff] text-center text-[20px]">
              選擇頭像
            </p>
          </div>
        </div>
        {/* Input Field */}
        <div className="px-[20px] mt-[20px] flex flex-row flex-wrap justify-center">
          {AVATAR_LIST.map((_, i) => {
            return (
              <div key={i} className="pb-[20px] px-[10px]">
                <img
                  src={`/assets/avatars/${i}.JPG`}
                  alt={`avatar-${i}`}
                  className="w-[100px] h-[100px] object-fit rounded-full cursor-pointer"
                  onClick={() => {
                    setUpdateAvatar(`${i}.JPG`);
                    setIsModalOpen(false);
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};
