// ./src/stores/index.ts
import { createContext, useContext } from "react";
import { STORE_ROUTER, RouterStore, history } from "./router";
import { NfcStore, STORE_NFC } from "./nfc";

function createStores() {
  return {
    [STORE_ROUTER]: new RouterStore(),
    [STORE_NFC]: new NfcStore(),
  };
}

export const stores = createStores();

export const StoresContext = createContext(stores);

const useStores = () => useContext(StoresContext);

export function useRouterStore() {
  const { routerStore } = useStores();
  return routerStore;
}

export function useNfcStore() {
  const { nfcStore } = useStores();
  return nfcStore;
}

export { history };

export const generalError = { success: false, error: "general_error" };
