import { Modal } from "antd";
import React, { useState } from "react";
import { openWarningNotification } from "./notification";
var md5 = require("md5");

export const PwdModal = (props: any) => {
  const { isModalOpen, setIsModalOpen, nfc, setEditMode } = props;

  const [updatePassword, setUpdatePassword] = useState("");

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const submit = () => {
    if (!nfc) return;
    if (nfc.password !== md5(updatePassword))
      return openWarningNotification("密碼不正確。");

    setIsModalOpen(false);
    setEditMode(true);
    setUpdatePassword("");
  };

  return (
    <Modal
      title={null}
      visible={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      <div className="my-[30px] flex flex-col justify-center align-center text-left relative">
        {/* Title */}
        <div className="absolute w-full top-[-80px] left-[0] right-[0]">
          <div
            className="bg-cover block h-[60px] w-[230px] mx-auto cursor-pointer"
            style={{
              backgroundImage: 'url("/assets/Popup_Title Box.png")',
            }}
          >
            <p className="leading-[60px] w-[230px] text-[#fff] text-center text-[20px]">
              更改內容
            </p>
          </div>
        </div>
        {/* Input password */}
        <div className="w-full bg-[red] mt-[20px]">
          <p className="text-[#fff] text-center text-[16px] py-[15px] font-[600]">
            請輸入密碼
          </p>
        </div>
        {/* Input Field */}
        <div className="px-[20px] mt-[30px]">
          <input
            value={updatePassword}
            placeholder="輸入密碼"
            className={`${$inputStyle}`}
            onChange={(e: any) => setUpdatePassword(e.target.value)}
            type="password"
          />
        </div>
        {/* Confirm Button */}
        <div className="relative w-full mt-[30px]">
          <div
            className="bg-cover block h-[60px] w-[140px] mx-auto cursor-pointer"
            style={{
              backgroundImage: 'url("/assets/Button_Popup.png")',
            }}
            onClick={() => submit()}
          >
            <p className="leading-[60px] w-[140px] text-[#fff] text-center text-[20px]">
              確定
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const $inputStyle =
  "font-[400] placeholder:!font-[400] text-[#aaa] placeholder:!text-[#aaa] text-[16px] placeholder:text-[16px] border-[1px] border-[#aaa]/50 hover:outline-none hover:border-[#aaa] block w-full duration-300 transition-all mt-[5px] px-[20px] py-[50px] rounded-[10px]";
