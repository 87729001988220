import React, { useState } from "react";
import {
  openSuccessNotification,
  openWarningNotification,
} from "./notification";
import { useNfcStore } from "src/stores";
var md5 = require("md5");

export const EditMode = (props: any) => {
  const {
    nfc,
    nfcId,
    setEditMode,
    getNfcDataCb,
    setIsSelectAvatarModalOpen,
    updateAvatar,
  } = props;
  const { name, title, company, wechat_handle, phone, email } = nfc || {};
  const isNew = !nfc?.has_updated || false;

  const { updateNfcData } = useNfcStore();

  const [updateName, setUpdateName] = useState(name || "");
  const [updateTitle, setUpdateTitle] = useState(title || "");
  const [updateCompany, setUpdateCompany] = useState(company || "");
  const [updateWechatHandle, setUpdateWechatHandle] = useState(
    wechat_handle || ""
  );
  const [updatePhone, setUpdatePhone] = useState(phone || "");
  const [updateEmail, setUpdateEmail] = useState(email || "");
  const [updatePassword, setUpdatePassword] = useState("");
  const [updateConfirmPassword, setUpdateConfirmPassword] = useState("");

  const submit = async () => {
    if (!nfc) return openWarningNotification("無法讀取此 NFC 卡。");
    if (!updateName || updateName.length === 0)
      return openWarningNotification("請輸入名字。");

    if (isNew) {
      if (!updatePassword) return openWarningNotification("請輸入密碼。");
      if (updatePassword !== updateConfirmPassword)
        return openWarningNotification("請輸入正確密碼。");
    }

    const result = await updateNfcData(nfcId, {
      name: updateName,
      avatar: updateAvatar,
      title: updateTitle,
      company: updateCompany,
      wechat_handle: updateWechatHandle,
      phone: updatePhone,
      email: updateEmail,
      has_updated: true,
      ...(isNew && {
        password: md5(updatePassword),
      }),
    });
    if (!result) return openWarningNotification("無法存儲 NFC 卡。");
    getNfcDataCb();
    setEditMode(false);
    return openSuccessNotification("成功儲存 NFC 卡。");
  };
  return (
    <div className="h-screen px-[20px] pt-[150px] z-[99] relative pb-[100px] overflow-y-scroll overflow-x-hidden relative">
      <img
        src={`/assets/avatars/${updateAvatar}`}
        alt="avatar"
        className="w-[55%] rounded-full"
        onClick={() => setIsSelectAvatarModalOpen(true)}
      />
      <div className="top-[-20px] relative">
        <input
          value={updateName}
          placeholder="輸入名字"
          className={`${$inputStyle} !text-[50px] placeholder:!text-[50px] !mt-[0px]`}
          onChange={(e: any) => setUpdateName(e.target.value)}
        />
        <input
          value={updateTitle}
          placeholder="輸入職位"
          className={`${$inputStyle}`}
          onChange={(e: any) => setUpdateTitle(e.target.value)}
        />
        <input
          value={updateCompany}
          placeholder="輸入公司名稱"
          className={`${$inputStyle}`}
          onChange={(e: any) => setUpdateCompany(e.target.value)}
        />
        {/* Separate Line */}
        <img
          src={`/assets/Line.png`}
          alt="line"
          className="w-[50%] pt-[20px] pb-[15px]"
        />
        {/* Extra Info */}
        <input
          value={updateWechatHandle}
          placeholder="輸入微信 ID"
          className={`${$inputStyle} !text-[20px] placeholder:!text-[20px]`}
          onChange={(e: any) => setUpdateWechatHandle(e.target.value)}
        />
        <input
          value={updatePhone}
          placeholder="輸入電話號碼"
          className={`${$inputStyle} !text-[20px] placeholder:!text-[20px]`}
          onChange={(e: any) => setUpdatePhone(e.target.value)}
        />
        <input
          value={updateEmail}
          placeholder="輸入電郵地址"
          className={`${$inputStyle} !text-[20px] placeholder:!text-[20px]`}
          onChange={(e: any) => setUpdateEmail(e.target.value)}
        />
      </div>
      {/* Separate Line */}
      {isNew && (
        <>
          <img
            src={`/assets/Line.png`}
            alt="line"
            className="w-[50%] pt-[0px] pb-[15px]"
          />
          <input
            value={updatePassword}
            placeholder="輸入密碼"
            className={`${$inputStyle} !text-[20px] placeholder:!text-[20px]`}
            onChange={(e: any) => setUpdatePassword(e.target.value)}
            type="password"
          />
          <input
            value={updateConfirmPassword}
            placeholder="輸入確認密碼"
            className={`${$inputStyle} !text-[20px] placeholder:!text-[20px]`}
            onChange={(e: any) => setUpdateConfirmPassword(e.target.value)}
            type="password"
          />
        </>
      )}

      {/* Confirm Button */}
      <div className="relative w-full top-[40px]">
        <div
          className="bg-cover block h-[60px] w-[140px] mx-auto cursor-pointer"
          style={{
            backgroundImage: 'url("/assets/Button_Popup.png")',
          }}
          onClick={() => submit()}
        >
          <p className="leading-[60px] w-[140px] text-[#fff] text-center text-[20px]">
            確定
          </p>
        </div>
      </div>
    </div>
  );
};

const $inputStyle =
  "bg-black/50 px-[5px] font-[900] placeholder:!font-[900] text-[#fff] placeholder:!text-[#fff] text-[24px] placeholder:text-[24px] border-[1px] border-[#fff]/50 hover:outline-none hover:border-[#fff] block w-full duration-300 transition-all mt-[5px]";
