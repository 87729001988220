import React from "react";

export const ViewMode = (props: any) => {
  const { nfc, setIsModalOpen } = props;
  const {
    avatar,
    name = "",
    title,
    company,
    wechat_handle,
    phone,
    email,
  } = nfc || {};

  return (
    <div className="h-screen px-[20px] pt-[150px] z-[99] relative pb-[100px] overflow-y-scroll overflow-x-hidden relative">
      {/* Edut Button */}
      <img
        src={`/assets/icon_more.png`}
        alt="edit"
        className="absolute w-[30px] top-[20px] right-[20px] cursor-pointer opacity-[0.5]"
        onClick={() => setIsModalOpen(true)}
      />
      {/* Profile Card */}
      <img
        src={`/assets/avatars/${avatar}`}
        alt="avatar"
        className="w-[55%] rounded-full"
      />
      <div className="top-[-20px] relative">
        <p className={$titleStyle} style={{ textShadow: "2px 2px #333" }}>
          {name}
        </p>
        {title && <p className={$subtitleStyle}>{title}</p>}
        {company && <p className={$subtitleStyle}>{company}</p>}
        {/* Separate Line */}
        <img
          src={`/assets/Line.png`}
          alt="line"
          className="w-[50%] pt-[10px] pb-[5px]"
        />
        {/* Extra Info */}
        <div className="grid grid-cols-3 gap-x-[10px] w-fit">
          {wechat_handle && (
            <img
              src="/assets/icon_wechat.png"
              alt="wechat"
              className={$iconStyle}
              onClick={() =>
                (window.location.href = `weixin://dl/chat?{${wechat_handle}}`)
              }
            />
          )}
          {phone && (
            <img
              src="/assets/icon_tel.png"
              alt="phone"
              className={`${$iconStyle} h-[21.32px] !w-auto mx-auto`}
              onClick={() => (window.location.href = `tel:${phone}`)}
            />
          )}
          {email && (
            <img
              src="/assets/icon_email.png"
              alt="email"
              className={$iconStyle}
              onClick={() => (window.location.href = `mailto:${email}`)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const $titleStyle = "font-[900] text-[#fff] text-[50px]";
const $subtitleStyle = "font-[400] text-[#fff] text-[24px]";
const $iconStyle = "w-[30px] cursor-pointer";
